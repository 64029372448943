export interface Lwin18AssetMetadata {
    id: string;
    vintage: number;
    caseSize: number;
    sizeML: string;
    lwin11: string;
    formattedBottleSize: string;
    lwin16: string;
}

export interface Lwin16AssetMetadata {
    lwin11: string;
    id: string;
    vintage: number;
    sizeML: string;
    formattedBottleSize: string;
}

/** returns wine information that is within the lwin18 identifier. */
export const parseLWin18 = (lwin18: string): Lwin18AssetMetadata => {
    const size = lwin18?.slice(13, 18);
    const formatedSize = formatedSizeML(size);
    const lwin16 = lwin18?.slice(0, 11) + size;

    return {
        lwin11: lwin18?.slice(0, 11),
        id: lwin18?.slice(0, 7),
        vintage: Number.parseInt(lwin18?.slice(7, 11), 10),
        caseSize: Number.parseInt(lwin18?.slice(11, 13), 10),
        sizeML: size,
        formattedBottleSize: formatedSize,
        lwin16
    };
};

/** returns wine information that is within lwin16 identifier */
export const parseLWin16 = (lwin16: string): Lwin16AssetMetadata => {
    const size = lwin16.slice(11, 16);
    const formatedSize = formatedSizeML(size);
    return {
        lwin11: lwin16.slice(0, 11),
        id: lwin16.slice(0, 7),
        vintage: Number.parseInt(lwin16.slice(7, 11), 10),
        sizeML: size,
        formattedBottleSize: formatedSize
    };
};

const formatedSizeML = (size: string): string => {
    switch (size) {
        case '00750':
            return '750ml';
        case '01500':
            return '1.5L';
        case '03000':
            return '3L';
        case '04500':
            return '4.5L';
        case '06000':
            return '6L';
        case '09000':
            return '9L';
        default:
            return size;
    }
};

class LwinBase {
    protected value: string;

    constructor(value: string) {
        this.value = value;
    }

    get lwin7(): string {
        return this.value.substring(0, 7);
    }

    get lwin11(): string {
        return this.value.substring(0, 11);
    }

    get lwin16(): string {
        return this.value.substring(0, 11) + this.value.substring(13, 18);
    }

    get vintage(): number {
        return parseInt(this.value.substring(7, 11), 10);
    }

    setVintage(value: number): LwinBase {
        if (value < 1900) {
            throw new Error('vintage must be greater than 1900');
        }
        if (value > 9999) {
            throw new Error('vintage must be less than 10000');
        }
        return new LwinBase(`${this.lwin7}${value.toString().padStart(4, '0')}${this.value.substring(11)}`);
    }

    get bottleVolume(): number {
        return parseInt(this.value.substring(13, 18), 10);
    }

    setBottleVolume(value: number): LwinBase {
        if (value < 1) {
            throw new Error('bottle volume must be greater than 0');
        }
        return new LwinBase(
            `${this.value.substring(0, 13)}${value.toString().padStart(5, '0')}${this.value.substring(18)}`
        );
    }

    get formattedBottleSize(): string {
        return formatedSizeML(this.value.substring(13, 18));
    }

    get case(): string {
        return this.value.substring(11, 18);
    }

    get bottlesInCase(): number {
        return parseInt(this.value.substring(11, 13), 10);
    }

    setBottlesInCase(value: number): LwinBase {
        return new LwinBase(`${this.lwin11}${value.toString().padStart(2, '0')}${this.value.substring(13)}`);
    }

    get winery(): string {
        return this.value.substring(0, 7);
    }

    toString(): string {
        return `LWin18('${this.value}')`;
    }
}

class LWin18 extends LwinBase {
    constructor(lwin: string) {
        if (lwin.length !== 18) {
            throw new Error(`lwin must be 18 characters long, got ${lwin.length} for '${lwin}'`);
        }
        super(lwin);
    }
}

export class ProductCode extends LwinBase {
    constructor(code: string) {
        if (code.length < 18) {
            throw new Error(`product code must be at least 18 characters long, got ${code.length} for '${code}'`);
        }
        if (code.length > 18 && code[18] !== '-') {
            throw new Error("product code must be 18 characters long and have a '-' at 19");
        }
        super(code);
    }

    get lwin18(): LWin18 {
        return new LWin18(this.value.substring(0, 18));
    }

    get variant(): string {
        return this.value.substring(19);
    }
}
