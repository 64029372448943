import { useMemo } from 'react';
import { useTradingPortfolioQuery } from './portfolios/trading/useTradingPortfolioQuery';
import { transformPortfoliosToKeys, useGetUserPortfolios } from './portfolios/useGetUserPortfolios';

// TODO: add the errors and stuff to the return object too
export const useGetUserPortfoliosInfo = () => {
    const { data: userPortfolios, isSuccess } = useGetUserPortfolios();
    // Since trading is NOT in portfolios we have to query it separately
    const {
        data: trading,
        isLoading: tradingLoading,
        isSuccess: isTradingSucess,
        error: tradingError
    } = useTradingPortfolioQuery();

    const portfolios = useMemo(() => {
        return userPortfolios?.portfolios ? transformPortfoliosToKeys(userPortfolios.portfolios) : undefined;
    }, [userPortfolios]);

    return useMemo(
        () => ({
            whiskey: portfolios?.whiskey,
            managed: portfolios?.managed,
            trading,
            userPortfolios,
            isWhiskeySuccess: isSuccess,
            isManagedSucces: isSuccess,
            isTradingSucess,
            tradingError,
            tradingLoading
        }),
        [portfolios, userPortfolios, isSuccess, trading, tradingLoading, isTradingSucess, tradingError]
    );
};
