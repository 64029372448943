/* eslint-disable max-classes-per-file */
import { UseVinovestNetworkingOptions, VinovestNetworkingClient } from '~/networking';
import type { ClientsideAuthentication } from '~/stores/ClientsideAuthentication';
import { getCurrencyCode } from '~/utils/localization';

const defaultConfig: UseVinovestNetworkingOptions = {
    getPortfolioId: () => '',
    getTradingPortfolioId: () => '',
    getUserId: () => ''
};

export interface VinovestNetworkHelperAuthenticationParams {
    userId?: string;
    portfolioId?: string;
    currencyCode?: string;
}
/**
 * Helper to get the vinonvest networking client based on client/server side requirements and okta availability.
 */
export class VinovestNetworkingHelper {
    static instance: VinovestNetworkingClient;

    static getUnauthenticatedClient(): VinovestNetworkingClient {
        return new VinovestNetworkingClient(defaultConfig);
    }

    static getClient(params?: VinovestNetworkHelperAuthenticationParams): VinovestNetworkingClient {
        if (!VinovestNetworkingHelper.instance) {
            // the problem here is that this is called from all sorts of places and the auth is stored in the
            // react tree. This class has had most of the functionality removed already but this last hack is
            // needed before ripping out most of how this is done, it's just a large charge.

            const getAuth = () => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-return
                return (typeof window !== 'undefined' && window?.Vino?.auth) as ClientsideAuthentication | undefined;
            };
            const getPortfolioId = () => getAuth()?.userId as string;
            const getUserId = () => getAuth()?.userId as string;
            const getTradingPortfolioId = () => getAuth()?.tradingPortfolioId || '';
            const paramCurrencyCode = () => params?.currencyCode || getCurrencyCode();

            VinovestNetworkingHelper.instance = new VinovestNetworkingClient({
                getPortfolioId,
                getUserId,
                getTradingPortfolioId,
                getCurrencyCode: paramCurrencyCode
            });
        }
        return VinovestNetworkingHelper.instance;
    }
}
