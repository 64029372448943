import { GetPortfolioWineResponse } from '~/networking/api/managed';
import { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { PortfolioPerformanceRanges } from '~/networking/models';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const getPortfolioWine =
    ({ getHeaders, getRequestUrl, getPortfolioId }: MakeMangedRequestOptions) =>
    async (
        lwin18: string,
        range: PortfolioPerformanceRanges,
        portfolioID?: string
    ): Promise<Response<GetPortfolioWineResponse>> => {
        const headers = getHeaders(true);
        const useablePortfolioID = portfolioID || getPortfolioId();
        const getPortfolioWineEndpoint = getRequestUrl(
            `/v1/portfolio/${useablePortfolioID}/wines/${lwin18}?range=${range}`
        );

        return httpRequest.get(getPortfolioWineEndpoint, { headers });
    };
