/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-argument */
// noinspection ES6RedundantNestingInTemplateLiteral

import '~/vinovest-design-system/theme.css';
import '~/components/modules/Modal/components/modal.css';
import '~/components/views/SignupNew/components/flag.css';

import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { getSelectorsByUserAgent } from 'react-device-detect';
import { QueryClient, setLogger } from 'react-query';
import type { AccessToken, UserClaims } from '@okta/okta-auth-js';
import type { NextPage } from 'next';
import type { AppContext, AppInitialProps, AppProps } from 'next/app';
import App from 'next/app';
import { NextRequest } from 'next/server';

import { GlobalEventListeners } from '~/components/global/GlobalEventListeners';
import { ErrorContainer } from '~/containers/ErrorContainer/ErrorContainer';
import ProviderContainer from '~/containers/ProviderContainer/ProviderContainer';
import { ZendeskHelper } from '~/helpers/ZendeskHelper';
import { useGoogleTagManager } from '~/hooks/useGoogleTagManager';
import { ServerUAProps } from '~/hooks/useWindowSize';
import { ApiTypes, setServerHostnames } from '~/networking';
import GlobalStyles from '~/styles/GlobalStyles';
import { ImpactHelper } from '~/tracking/impact/ImpactHelper';
import { ClientsideAuthentication } from '~/stores/ClientsideAuthentication';
import { GoogleTagManagerScript } from '~/components/global/document/head/GoogleTagManagerScript';
import { PosthogHelper } from '~/helpers/PosthogHelper';
import {
    favoritMonostd,
    favoritstd,
    roslindale,
    vinovestMedium,
    vinovestMono
} from '~/components/global/document/head/fonts';
import { SentryHelper } from '~/helpers/Sentry/SentryHelper';
import { toJS } from 'mobx';
import { usePreviousRoute } from '~/hooks/usePreviousRoute';
import { useUrlModalListener } from '~/helpers/UrlModalHelper';
import { DefaultSeo } from 'next-seo';

type NextPageWithLayout = NextPage & {
    getLayout?: (page: ReactElement) => ReactNode;
};

type ServerProps = AppInitialProps & {
    hostnames?: Record<ApiTypes, string>;
    serverDevice: ServerUAProps;
    auth?: { jwt: AccessToken };
};

type AppPropsWithLayout = AppProps &
    ServerProps & {
        Component: NextPageWithLayout;
    };

function VinovestApp({
    Component,
    pageProps: { ...pageProps },
    router,
    hostnames,
    serverDevice,
    auth
}: AppPropsWithLayout) {
    useGoogleTagManager();
    ZendeskHelper.setZendeskSettings();
    usePreviousRoute();
    useUrlModalListener();

    useEffect(() => {
        // Track page views https://posthog.com/docs/libraries/next-js#client-side-setup
        const handleRouteChange = () => PosthogHelper.capture('$pageview');
        router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events]);

    useEffect(() => {
        ImpactHelper.setImpactClickIDCookie(router.query);
    }, [router.pathname, router.query]);

    if (hostnames) {
        setServerHostnames(hostnames);
    }

    const getLayout = Component.getLayout || ((page) => page);

    const [queryClient] = useState(() => new QueryClient({ defaultOptions: { queries: { staleTime: 1000 * 10 } } }));

    useEffect(() => {
        const store = ClientsideAuthentication.getInstance();
        if (auth && store.loading) {
            store.handleServerSideAuth(auth.jwt);
        }

        /* eslint-disable */
        const win = window as any;
        win.Vino = win.Vino || {};
        win.Vino.auth = store;
        win.toJS = toJS;
    }, [auth]);

    useEffect(() => {
        setLogger({
            log: (message) => {
                console.info(message);
                SentryHelper.captureMessage(message);
            },
            warn: (message) => {
                console.warn(message);
                SentryHelper.captureMessage(message);
            },
            error: (error) => {
                console.error(error);
                SentryHelper.captureException(error);
            }
        });
        window.Vino = window.Vino || {};
        window.Vino.sentry = SentryHelper;
    }, []);
    return getLayout(
        <div
            className={`${vinovestMono.variable} ${vinovestMedium.variable} ${
                roslindale.variable
            } ${favoritstd.variable} ${favoritMonostd.variable}`}
        >
            <DefaultSeo
                openGraph={{
                    site_name: 'Vinovest',
                    url: 'https://www.vinovest.co',
                    title: 'Vinovest | Investing in Wine as an Alternative Asset',
                    description: `It's time to invest in fine wine.`,
                    images: [{ url: 'https://cms.cdn4vest.com/images/simple_modern_wine_investing.original.png' }]
                }}
                twitter={{
                    handle: '@vinovest1',
                    site: '@vinovest1',
                    cardType: 'summary_large_image'
                }}
            />
            <ErrorContainer />
            <ProviderContainer
                dehydratedState={pageProps.dehydratedState}
                queryClient={queryClient}
                serverDevice={serverDevice}
            >
                <GlobalStyles />
                <GlobalEventListeners queryClient={queryClient} />

                <Component {...pageProps} />
            </ProviderContainer>
            <GoogleTagManagerScript />
        </div>
    );
}

VinovestApp.getInitialProps = async (appContext: AppContext) => {
    const req = appContext.ctx.req as unknown as NextRequest;
    const isServer = !!req;
    const appProps: Partial<ServerProps> = await App.getInitialProps(appContext);

    let userAgent = ((req?.headers as any) || {})['user-agent'];
    if (typeof window !== 'undefined') {
        userAgent = navigator.userAgent;
    }
    if (userAgent) {
        const { isMobile, isDesktop, isTablet } = getSelectorsByUserAgent(userAgent);
        appProps.serverDevice = {
            isMobile,
            isDesktop,
            isTablet
        };
    } else {
        appProps.serverDevice = {
            isMobile: false,
            isDesktop: false,
            isTablet: false
        };
    }

    // eslint-disable-next-line prefer-destructuring
    const cookies: Record<string, any> = req?.cookies;
    if (isServer && cookies && cookies['okta-access-token'] && cookies['okta-access-token']) {
        appProps.hostnames = {
            [ApiTypes.CMS]: process.env[`${'NEXT'}_PUBLIC_CMS_API_URL`],
            [ApiTypes.Managed]: process.env[`${'NEXT'}_PUBLIC_BASE_ENDPOINT`],
            [ApiTypes.Trading]: process.env[`${'NEXT'}_PUBLIC_BASE_TRADING_ENDPOINT`]
        };

        const OktaJwtVerifier = (await import('@okta/jwt-verifier')).default;
        const verifier = new OktaJwtVerifier({
            issuer: process.env.NEXT_PUBLIC_OKTA_ISSUER,
            clientId: process.env.NEXT_PUBLIC_OKTA_CLIENT_ID
        });

        try {
            const jwt = await verifier.verifyAccessToken(
                cookies['okta-access-token'],
                process.env.NEXT_PUBLIC_OKTA_AUD
            );
            appProps.auth = {
                jwt: {
                    claims: jwt.claims as UserClaims,
                    accessToken: cookies['okta-access-token'],
                    tokenType: 'Bearer',
                    userinfoUrl: '',
                    expiresAt: jwt.claims.exp,
                    authorizeUrl: '',
                    scopes: []
                }
            };
        } catch (error) {
            console.info('invalid token %O', error);
        }
    }

    return appProps as ServerProps;
};

export default VinovestApp;
